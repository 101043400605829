import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private _authService: AuthService, private fb: FormBuilder) { } 
  form: FormGroup;
  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }


  forgotPassword() {
    // this._authService.forgotPassword(this.form.controls.email.value);
  }
}
