import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CONFIG } from './../../../config/config'
import { AuthService } from './../../../services/auth.service'
import { AirtableService } from "./../../../services/airtable.service";
import { naratives } from "./../narative";

@Component({
  selector: 'app-impact-scores',
  templateUrl: './impact-scores.component.html',
  styleUrls: ['./impact-scores.component.scss']
})
export class ImpactScoresComponent implements OnInit {

  @Input() scoresGraphData: any = {}
  cisInfo: any = {}
  @Input() scores: any
  @Input() set_classnames: any
  @Input() risk_texts: any

  @Input() footprint: any
  naratives: any = naratives

  cis_form_err: any;
  business_class: any = []
  business_subclass: any = []
  regulatory_category: any = []
  businessClassData : any  = [] 
  crp: any = {
    organisation_name: "",
    global_footprint: "",
    legal_domicile: "",
    organisation_address: "",
    type_of_entity: "",
    primary_contact: "",
    organisation_website: "",
    organisation_type: "",
  }

  get_cis_score: any = {
    no_of_employees: "",
    business_class: "",
    business_subclass: "",
    regulatory_category: "",
    NAICS_NACE_code: "",
    annual_revenue: "",
    tradeClassId: []

  }

  employes_count: any = [
    { "Employee Number": "0 - 50", "Risk Metric": "0" },
    { "Employee Number": "50 - 200", "Risk Metric": "-0.005" },
    { "Employee Number": "200 - 500", "Risk Metric": "-0.007" },
    { "Employee Number": "500 - 1000", "Risk Metric": "-0.009" },
    { "Employee Number": "1000 - 5000", "Risk Metric": "-0.01" },
    { "Employee Number": "5000 - 10,000", "Risk Metric": "-0.02" },
    { "Employee Number": "10,000 - 25,000", "Risk Metric": "-0.025" },
    { "Employee Number": "25,000 +", "Risk Metric": "-0.03" },
  ]
  revenues: any = [
    // { "Revenue Number": "0 - 1,000,000", "Risk Metric": "0.000", "value": "0.790" },
    // { "Revenue Number": "1,000,000 - 5,000,000", "Risk Metric": "-0.004", "value": "0.790" },
    // { "Revenue Number": "5,000,000 - 20,000,000", "Risk Metric": "-0.007", "value": "0.720" },
    // { "Revenue Number": "20,000,000 - 50,000,000", "Risk Metric": "-0.01", "value": "0.660" },
    // { "Revenue Number": "50,000,000 - 100,000,000", "Risk Metric": "-0.015", "value": "0.600" },
    // { "Revenue Number": "100,000,000 +", "Risk Metric": "-0.02", "value": "0.600" }

    { "Revenue Number": "Less than 1.0", "Risk Metric": "0.000", "value": "0.790" },
    { "Revenue Number": "1.0 - 4.99", "Risk Metric": "-0.004", "value": "0.790" },
    { "Revenue Number": "5.0 - 19.99", "Risk Metric": "-0.007", "value": "0.720" },
    { "Revenue Number": "20.0 - 49.99", "Risk Metric": "-0.01", "value": "0.660" },
    { "Revenue Number": "50.0 - 99.99", "Risk Metric": "-0.015", "value": "0.600" },
    { "Revenue Number": "100.0 - 499.99", "Risk Metric": "-0.02", "value": "0.600" },
    { "Revenue Number": "500.0 - 999.99", "Risk Metric": "-0.02", "value": "0.545" },
    { "Revenue Number": "1,000.0 - 4,999.99", "Risk Metric": "-0.02", "value": "0.545" },
    { "Revenue Number": "5,000.0 or more", "Risk Metric": "-0.02", "value": "0.500" }

  ];

  // risk_texts: any = {
  //   grs: '',
  //   css: '',
  //   cis: "",
  //   composite_grs_score: ""
  // }

  // scores: any = {
  //   css: 0,
  //   grs: 0,
  //   cis: 0,
  //   composite_grs_score: 0
  // }

  cisValue: any = {
    location: '',
    class: '',
    subclass: '',
    regulatory_category: ''
  }
  @Output() calcis: any = new EventEmitter<any>()

  constructor(
    private authService: AuthService,
    private airtable: AirtableService, ) { }


  ngOnInit() {
    this.getUserInfo();
    this.getBusinessClassData();
  }

  ngOnChanges() {
   
  }

  async calcCISNew() {
    console.log(this.crp);
    for (let key in this.get_cis_score) {
      this.crp[key] = this.get_cis_score[key]
    }
    console.log(this.crp);
    try {
      let res = await this.airtable.crpInfoNew(this.crp)
      let response = res.json()
      if(response.success){
       this.calcis.emit(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getBusinessClassData() {
    try {
       let res = await this.authService.commonData()
       let response = res.json()
       if (response.success) {
        this.businessClassData = response.data;
       }
            
    } catch (error) {
      console.log(error);
    }
  }


  async getcisvalues(obj, type, val) {
    this[type] = []
    try {
      let res = await this.authService.cisValues(obj)
      let response = res.json()

      if (response.success) {
        for (let key of response.metadata) {
          if (!this[type].includes(key[val])) {
            this[type].push(key[val])
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getValues(e, type, val, subval) {
    this.cisValue[val] = (e.target.textContent).trim(/ /g, "");

    if (val == 'class') {
      this.cisValue[subval] = '',
        this.cisValue.regulatory_category = ''
      this.get_cis_score.business_subclass = ''
      this.get_cis_score.regulatory_category = ''
    }
    if (val == 'subclass') {
      this.cisValue.regulatory_category = ''
      this.get_cis_score.regulatory_category = ''
    }
    this.getcisvalues(this.cisValue, type, subval)

  }

  async calcCIS() {

    for (let key in this.get_cis_score) {
      this.crp[key] = this.get_cis_score[key]
    }

    try {
      let res = await this.airtable.crpInfo(this.crp)
      let response = res.json()
      if (response.success) {
        this.calcis.emit(true)
      }
    } catch (error) {
      console.log(error);
    }
  }



  show_more_text: any
  setShowMore(risk_texts) {
    if (risk_texts == "composite_GRS") {
      this.show_more_text = this.naratives['composite_GRS']['info'];
      return
    }
    this.show_more_text = this.naratives[this.footprint][risk_texts];
  }


  async getUserInfo() {
    try {
      let res = await this.authService.profile();
      let response = res.json()
      console.log(response);
      if (response.success) {
        if (response.user) {
          this.cisInfo = response.user;
          await this.applyValuesIfExistis();
        }
      }
    } catch (error) {
      console.log("Yup , Eror");
      console.log(error);
    }
  }


  async applyValuesIfExistis() {
    if (this.cisInfo) {

      if (this.cisInfo.global_footprint) {
        this.cisValue.location = this.cisInfo.global_footprint
        this.getcisvalues(this.cisValue, 'business_class', 'class')
      }
      for (let key in this.cisInfo) {
        for (let val in this.crp) {
          if (key == val) {
            this.crp[val] = this.cisInfo[key]
          }
        }
      }

      for (let key in this.cisInfo) {
        for (let val in this.get_cis_score) {
          if (key == val) {
            if (key == 'business_class' && this.cisInfo[key]) {
              let obj = {
                target: {
                  textContent: this.cisInfo[key]
                }
              }
              this.getValues(obj, 'business_subclass', 'class', 'subclass')
            }
            if (key == 'business_subclass' && this.cisInfo[key]) {
              let obj = {
                target: {
                  textContent: this.cisInfo[key]
                }
              }
              this.getValues(obj, 'regulatory_category', 'subclass', 'regulatory_category')
            }
            this.get_cis_score[key] = this.cisInfo[key]
          }
        }
      }
    }
  }
}
