import { Component, OnInit } from '@angular/core';

import { AuthService } from './../../services/auth.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  signUpDetails: any = {
    email: '',
    password: "",
    first_name: "",
    last_name: "",
  }
  form: FormGroup;
  error_message: any = null
  loading: boolean = false
  constructor(private _authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router, ) { }
    type_password: any = 'password'

  ngOnInit() {

    this.form = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      agree: [false, [Validators.required, Validators.requiredTrue]]
    })
  }

  togglePassword() {
    this.type_password == 'password' ? this.type_password = 'text' : this.type_password = 'password'
  }

  async  createAccount(e) {
    let th = this
    e.preventDefault();
    this.loading = true
    let signUpDetails = {
      email: this.form.controls.email.value,
      password: this.form.controls.password.value,
      first_name: this.form.controls.first_name.value,
      last_name: this.form.controls.last_name.value
    }
    try {
      let res = await this._authService.register(signUpDetails);
      console.log(res, "response from FB");
      let response = res.json()
      console.log(response);      
      if (!response.success) {
        this.error_message = response.message
        // window.localStorage.clear();
      } else {
        window.localStorage.setItem("token", response.token)
        this.router.navigate(['/crp/false']);
        console.log('token');        
        // window.location.href = "crp"
      }
    } catch (error) { 
      console.log(error);      
    }

    this.loading = false
  }




}
