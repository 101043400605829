import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import Dexie from 'dexie';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist/bundles/ng-chartist.umd.js";
// import * as firebase from 'firebase/app';
import { AuthService } from './../../services/auth.service';
import { naratives } from "./narative";
import { AirtableService } from "./../../services/airtable.service";
import { CONFIG } from './../../config/config'
import { Router } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

// interface IFriend {
//   question?: string;
//   answer?: string;
//   points?: number;
//   ans_string?: string;
// }

// class QuestionsStore extends Dexie {

//   answers: Dexie.Table<IFriend, number>;

//   constructor() {
//     super('CloudCover');
//     this.version(1).stores({
//       answers: '++question'
//     });
//   }
// }

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})


export class HomeComponent implements OnInit {
  info_open: boolean = false
  Token_ID: any = window.localStorage.getItem('uid') || null
  uid: any = window.localStorage.getItem("uid") || null

  grs_step: any = 1
  css_step: any = window.localStorage.getItem('cssStep') || 1

  cssErr: boolean = false
  cis_form_err: boolean = false

  selected_bussiness: any = null
  business_sub_classes: any
  selected_sub_bussiness: any
  selected_reg_category: any
  regulator_category: any
  semi_impact_cis_score_arr: any
  semi_impact_cis_score: any
  countriesData: any = []

  needCRPUpdateGRS: boolean = false
  openModal: boolean = false
  bussiness_classes: any = [
    "Manufacturing", "Professional Services", "Retail", "Trademark", "Life Sciences", "Financial"
  ]

  employes_count: any = [
    { "Employee Number": "0 - 50", "Risk Metric": "0" },
    { "Employee Number": "50 - 200", "Risk Metric": "-0.005" },
    { "Employee Number": "200 - 500", "Risk Metric": "-0.007" },
    { "Employee Number": "500 - 1000", "Risk Metric": "-0.009" },
    { "Employee Number": "1000 - 5000", "Risk Metric": "-0.01" },
    { "Employee Number": "5000 - 10,000", "Risk Metric": "-0.02" },
    { "Employee Number": "10,000 - 25,000", "Risk Metric": "-0.025" },
    { "Employee Number": "25,000 +", "Risk Metric": "-0.03" },
  ]
  revenues: any = [
    // { "Revenue Number": "0 - 1,000,000", "Risk Metric": "0.000", "value": "0.790" },
    // { "Revenue Number": "1,000,000 - 5,000,000", "Risk Metric": "-0.004", "value": "0.790" },
    // { "Revenue Number": "5,000,000 - 20,000,000", "Risk Metric": "-0.007", "value": "0.720" },
    // { "Revenue Number": "20,000,000 - 50,000,000", "Risk Metric": "-0.01", "value": "0.660" },
    // { "Revenue Number": "50,000,000 - 100,000,000", "Risk Metric": "-0.015", "value": "0.600" },
    // { "Revenue Number": "100,000,000 +", "Risk Metric": "-0.02", "value": "0.600" }

    { "Revenue Number": "Less than 1.0", "Risk Metric": "0.000", "value": "0.790" },
    { "Revenue Number": "1.0 - 4.99", "Risk Metric": "-0.004", "value": "0.790" },
    { "Revenue Number": "5.0 - 19.99", "Risk Metric": "-0.007", "value": "0.720" },
    { "Revenue Number": "20.0 - 49.99", "Risk Metric": "-0.01", "value": "0.660" },
    { "Revenue Number": "50.0 - 99.99", "Risk Metric": "-0.015", "value": "0.600" },
    { "Revenue Number": "100.0 - 499.99", "Risk Metric": "-0.02", "value": "0.600" },
    { "Revenue Number": "500.0 - 999.99", "Risk Metric": "-0.02", "value": "0.545" },
    { "Revenue Number": "1,000.0 - 4,999.99", "Risk Metric": "-0.02", "value": "0.545" },
    { "Revenue Number": "5,000.0 or more", "Risk Metric": "-0.02", "value": "0.500" }

  ];

  loginInfo: any = {
    first_name: '',
    last_name: '',
  }

  cisInfo: any = {}

  GRS: any = CONFIG.QUESTIONS.GRS
  CSS = CONFIG.QUESTIONS.CSS
  sub_css: any = []
  semi_css: any

  per_page: number = 6
  crp_data: any


  scores: any = {
    css: 0,
    grs: 0,
    cis: 0,
    composite_grs_score: 0
  }
  get_cis_score: any = {
    "Employee Count": '',
    "Business Class": "",
    "Sub Class": "",
    "Regulatory Category": "",
    "NAICS/NACE Code": "",
    "Annual Revenue ($ million)": ""
  }

  total_count_usa: any
  usa_page_num: any = 1
  maxSize: any = 5
  loading: boolean = false

  error_message: any = {
    type: "error",
    message: ""
  }


  getScoresDetails: any
  scoresGraphData: any = {};
  footprint: any


  show: boolean = false
  remove_sugg: boolean = false
  naratives: any = naratives
  form: FormGroup;

  show_grs_score_in_ui: boolean = false;



  answers: any = [];
  allAnswers: any = [];
  indexOffset: number = 2;
  perPage: number = Math.floor((this.GRS.length - 2) / 3);
  scoreGRS: number = 0;
  multiplicationFactorGRS: number = 1;






  constructor(
    private authService: AuthService,
    private hostElement: ElementRef,
    private airtable: AirtableService,
    private router: Router,
    private fb: FormBuilder) {
    this.getUserInfo();

    // if (!window.localStorage.getItem("footprint")) {
    //   this.router.navigate(['/crp/false']);
    // }
  }



  ngOnInit() {

    this.form = this.fb.group({
      specific_no_days: [null, Validators.compose([
        Validators.required,
        Validators.max(180),
        Validators.min(31)
      ])],
    });


    // for (const obj of this.GRS) {
    //   if (obj && obj.is_multiple) {
    //     obj.seleted_multiple_array = [];
    //     obj.selected_values_arr = []
    //     obj.new_options = []

    //     for (const key in obj.options) {
    //       let simple_obj = {
    //         string: key,
    //         value: obj.options[key],
    //         checked: false
    //       }
    //       obj.new_options.push(simple_obj)
    //     }
    //   }
    // }

    // this.sub_css = this.CSS.slice(0, 4);
    // this.updateScoresGraphData();
    // this.getCrpData();
    // this.getScores();
    // this.getBussinessClass();


    // this.getEmployeesCount();
    // this.getRevenuesCount();

    // Here, insert all the questions and the default data in the db.
    // for (let i of CONFIG.QUESTIONS.CSS) {
    //   this.db.answers.add({ 'question': i.question, 'answer': null, 'ans_string': null });
    // }

    // this.getStepsNumberFromFB_DB();

    this.getCountriesData()
    this.getUserInfo()



  }



  async getCountriesData() {
    try {
      let res = await this.authService.commonData()
      let response = res.json()
      if (response.success) {
        this.countriesData = response.data;
      }

    } catch (error) {
      console.log(error);
    }
  }



  ////////////////////////
  ////// for CIS , GRS , CSS
  ////// Common Functions
  //////////////////////////


  // async getStepsNumberFromFB_DB() {
  //   this.loading = true
  //   try {
  //     const docRef = this.fb_db.collection('steps').doc(this.uid);
  //     const grs_docRef = this.fb_db.collection('grs_data').doc(this.uid);
  //     const grs_data_exists = await grs_docRef.get();
  //     const data_exists = await docRef.get();
  //     if (data_exists.exists) {
  //       let steps_data = data_exists.data();
  //       if (steps_data) {
  //         this.css_step = steps_data.css_step
  //         this.grs_step = steps_data.grs_step
  //         this.goToThatStep(this.grs_step);
  //         this.getDataForStepCss(this.css_step)
  //       }
  //     }

  //     if (grs_data_exists.exists) {
  //       let multiple_count_data = grs_data_exists.data();
  //       if (multiple_count_data) {
  //         if (multiple_count_data.total_selcted_mutliple_check) {
  //           this.total_selcted_mutliple_check = multiple_count_data.total_selcted_mutliple_check
  //           this.total_check_times = multiple_count_data.total_check_times
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log("error ", error);
  //   }
  //   this.loading = false
  // }

  // async getScores() {
  //   try {
  //     let res: any = await this.airtable.getScores()
  //     console.log("response of Getscores :", res);
  //     this.getScoresDetails = res.success;
  //     if (this.getScoresDetails || this.getScoresDetails[0]) {
  //       if (this.getScoresDetails[0]) {
  //         this.getScoresDetails = this.getScoresDetails[0];
  //       }

  //       this.scores = {
  //         css: this.getScoresDetails.CSS_Score == '0' ? this.getScoresDetails.CSS_Score : Number(this.getScoresDetails.CSS_Score).toFixed(3),
  //         cis: this.getScoresDetails.CIS_Score == '0' ? this.getScoresDetails.CIS_Score : Number(this.getScoresDetails.CIS_Score).toFixed(3),
  //         grs: this.getScoresDetails.GRS_Score == '0' ? this.getScoresDetails.GRS_Score : Number(this.getScoresDetails.GRS_Score).toFixed(3),
  //       }
  //       // if composite_grs exits priory should be for this
  //       if (this.getScoresDetails.composite_grs_score) {

  //         let data_governance = this.getScoresDetails.data_governance
  //         let physical_security = this.getScoresDetails.physical_security
  //         let internal_security = this.getScoresDetails.internal_security
  //         let external_security = this.getScoresDetails.external_security

  //         if (data_governance && physical_security && internal_security && external_security) {
  //           // now we can show the score in Ui
  //           console.log("Now Composite score is ready");
  //           if (Number(this.getScoresDetails.composite_grs_score) > 0) {
  //             this.scores.composite_grs_score = Number(this.getScoresDetails.composite_grs_score).toFixed(3)
  //           }
  //           this.show_grs_score_in_ui = true
  //         } else {
  //           this.show_grs_score_in_ui = false
  //         }
  //       }

  //       // this.updateScoresGraphData();
  //       console.log("Scores Object is : ", this.scores);
  //     }
  //   } catch (error) {
  //     console.log(error, 'err');
  //   }
  // }

  set_classnames: any = {
    grs: '',
    css: '',
    cis: "",
    composite_grs_score: ""
  }

  risk_texts: any = {
    grs: '',
    css: '',
    cis: "",
    composite_grs_score: ""
  }

  updateScoresGraphData() {

    let set_classnames = {
      grs: '',
      css: '',
      cis: "",
      composite_grs_score: ""
    }

    let classNames = ['major_risk', 'cautionary_risk', 'moderate_risk', 'minor_risk', 'acceptable_risk'];

    for (let i in this.scores) {
      if (this.scores[i] == 0) {
        set_classnames[i] = "major_risk zero_score"
      } else if (this.scores[i] < 0.5) {
        set_classnames[i] = classNames[0]
        this.risk_texts[i] = 'Major Risk';
      } else if (this.scores[i] < 0.6) {
        set_classnames[i] = classNames[1]
        this.risk_texts[i] = 'Cautionary Risk';
      } else if (this.scores[i] < 0.7) {
        set_classnames[i] = classNames[2]
        this.risk_texts[i] = 'Moderate Risk';
      } else if (this.scores[i] < 0.8) {
        set_classnames[i] = classNames[3]
        this.risk_texts[i] = 'Minor Risk';
      } else {
        set_classnames[i] = classNames[4]
        this.risk_texts[i] = 'Acceptable Risk';
      }
    }

    let new_text_colors = ["cmpst-deep-red", 'cmpst-red', 'cmpst-red-orange', 'cmpst-orange', 'cmpst-yellow', 'cmpst-green', 'cmpst-blue', 'cmpst-ultramarine']
    for (let i in this.scores) {
      if (i == 'composite_grs_score') {
        if (this.scores[i] == 0) {
          set_classnames[i] = "major_risk zero_score"
        } else if (this.scores[i] > 0 && this.scores[i] <= 0.559) {
          this.risk_texts[i] = 'Major Risk';
        } else if (this.scores[i] >= 0.560 && this.scores[i] <= 0.625) {
          this.risk_texts[i] = 'Cautionary Risk';
        } else if (this.scores[i] >= 0.626 && this.scores[i] <= 0.664) {
          this.risk_texts[i] = 'Moderate Risk';
        } else if (this.scores[i] >= 0.665 && this.scores[i] <= 0.7) {
          this.risk_texts[i] = 'Minor Risk';
        } else {
          this.risk_texts[i] = 'Acceptable Risk';
        }



        if (this.scores[i] > 0 && this.scores[i] <= 0.250) {
          set_classnames[i] = new_text_colors[0]
        }

        if (this.scores[i] > 0.250 && this.scores[i] <= 0.400) {
          set_classnames[i] = new_text_colors[1]
        }

        if (this.scores[i] > 0.400 && this.scores[i] <= 0.500) {
          set_classnames[i] = new_text_colors[2]
        }
        if (this.scores[i] > 0.500 && this.scores[i] <= 0.600) {
          set_classnames[i] = new_text_colors[3]
        }
        if (this.scores[i] > 0.600 && this.scores[i] <= 0.700) {
          set_classnames[i] = new_text_colors[4]
        }

        if (this.scores[i] > 0.700 && this.scores[i] <= 0.800) {
          set_classnames[i] = new_text_colors[5]
        }
        if (this.scores[i] > 0.800 && this.scores[i] <= 0.900) {
          set_classnames[i] = new_text_colors[6]
        }
        if (this.scores[i] > 0.900 && this.scores[i] <= 0.999) {
          set_classnames[i] = new_text_colors[7]
        }


      }
    }

    this.set_classnames = set_classnames

    let Major_composite_score = 0;
    if (this.scores.composite_grs_score) {
      Major_composite_score = this.scores.composite_grs_score
    } else {
      Major_composite_score = 0
      set_classnames["composite_grs_score"] = "major_risk zero_score"
    }

    this.scoresGraphData = {
      cis: {
        data: {
          series: [
            {
              value: 100 - this.scores.cis * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: this.scores.cis * 100,
              className: `${set_classnames.cis} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.cis,
        heading: "Compliance Impact Score",
        score: this.scores.cis,
        data_type: 'cis'
      },
      grs: {
        data: {
          series: [
            {
              value: 100 - this.scores.grs * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: this.scores.grs * 100,
              className: ` ${set_classnames.grs} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.grs,
        heading: "Governance Risk Score",
        score: this.scores.grs,
        data_type: 'grs'
      },
      composite_grs: {
        data: {
          series: [
            {
              value: 100 - Major_composite_score * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: Major_composite_score * 100,
              className: ` ${set_classnames.composite_grs_score} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.composite_grs_score,
        heading: "Governance Risk Score",
        score: Major_composite_score,
        data_type: 'composite_grs'
      },
      css: {
        data: {
          series: [
            {
              value: 100 - this.scores.css * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: this.scores.css * 100,
              className: `${set_classnames.css} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.css,
        heading: "CyberSafety Score",
        score: this.scores.css,
        data_type: 'css'
      },
    }
  }





  // async putScores() {
  //   try {
  //     let res = await this.airtable.putScores(this.scores);

  //   } catch (error) {
  //     console.log(error);
  //   }

  //   // this.updateScoresGraphData();
  //   // this.getScores()
  // }




  // async updateStepCountInFB_DB() {

  //   this.loading = true
  //   try {

  //     const docRef = this.fb_db.collection('steps').doc(this.uid);
  //     const grs_docRef = this.fb_db.collection('grs_data').doc(this.uid);
  //     const data_exists = await docRef.get();
  //     const grs_data_exists = await grs_docRef.get();
  //     let steps = {
  //       css_step: this.css_step,
  //       grs_step: this.grs_step
  //     }
  //     if (data_exists.exists) {

  //       const data_updated = await docRef.update(steps);
  //     } else {

  //       const data_added = await docRef.set(steps);
  //     }

  //     let convert_obj = {
  //       // grs_data : this.GRS
  //       total_selcted_mutliple_check: this.total_selcted_mutliple_check,
  //       total_check_times: this.total_check_times
  //     }

  //     if (grs_data_exists.exists) {

  //       const data_updated = await grs_docRef.update(convert_obj);
  //     } else {

  //       const data_added = await grs_docRef.set(convert_obj);
  //     }
  //   } catch (error) {

  //   }

  //   this.loading = false
  // }


  /////// Common functions END ////////



  //////////////////////
  // CRP DATA
  //////////////////////


  // async getCrpData() {
  //   try {
  //     let res: any = await this.airtable.getCrpData();
  //     this.crp_data = res.success;
  //     if (this.crp_data.length > 0) {
  //       this.crp_data = this.crp_data[0];
  //       console.log("Total CRP Data is :", this.crp_data);
  //       if (this.crp_data.CIS_Score) {
  //         this.scores = {
  //           css: this.crp_data.CSS_Score == '0' ? this.crp_data.CSS_Score : Number(this.crp_data.CSS_Score).toFixed(3),
  //           cis: this.crp_data.CIS_Score == '0' ? this.crp_data.CIS_Score : Number(this.crp_data.CIS_Score).toFixed(3),
  //           grs: this.crp_data.GRS_Score == '0' ? this.crp_data.GRS_Score : Number(this.crp_data.GRS_Score).toFixed(3),
  //         }

  //         // if composite_grs exits priory should be for this
  //         if (this.crp_data.composite_grs_score) {


  //           let data_governance = this.crp_data.data_governance
  //           let physical_security = this.crp_data.physical_security
  //           let internal_security = this.crp_data.internal_security
  //           let external_security = this.crp_data.external_security

  //           if (data_governance && physical_security && internal_security && external_security) {
  //             // now we can show the score in Ui
  //             console.log("Now Composite score is ready");
  //             this.show_grs_score_in_ui = true

  //             if (Number(this.crp_data.composite_grs_score) > 0) {
  //               this.scores.composite_grs_score = Number(this.crp_data.composite_grs_score).toFixed(3)
  //             }
  //           } else {
  //             // Dont show the GRS Score
  //             this.show_grs_score_in_ui = false
  //           }

  //         }

  //       }

  //       // Global_Footprint
  //       if (this.crp_data.Global_Footprint) {
  //         this.footprint = this.crp_data.Global_Footprint;
  //         this.airtable.footprint = this.footprint
  //       }


  //       //////////////////////////
  //       // this is update the data of CSS
  //       /////////////////////////
  //       this.getDataForStepCss(this.css_step);
  //       this.getLocationRiskMetric();
  //       this.getDataForGrs();


  //       //////////////////////////
  //       // Here We are updating the data of CIS
  //       /////////////////////////
  //       window.localStorage.setItem('footprint', this.crp_data.Global_Footprint)
  //       for (let i in this.get_cis_score) {
  //         for (let key in this.crp_data) {
  //           if (key == i) {
  //             this.selected_sub_bussiness = this.crp_data["Sub Class"];
  //             this.selected_bussiness = this.crp_data["Business Class"];
  //             this.selected_reg_category = this.crp_data["Regulatory Category"];
  //             this.get_cis_score[i] = this.crp_data[key]
  //           }
  //         }
  //       }
  //       if (this.selected_bussiness) {
  //         this.selectBussinesClass('e', this.selected_bussiness);
  //       }


  //       if (this.selected_sub_bussiness) {
  //         this.getRegularCategories('e', this.selected_sub_bussiness)
  //       }
  //       if (this.selected_reg_category) {
  //         this.getImactScores(this.selected_reg_category)
  //       }
  //     }

  //   } catch (error) {
  //     console.log(error, 'error on Get CRP Api');
  //     let msg = {
  //       type: "error",
  //       message: "Internal Server Error!"
  //     }
  //     this.openNotification(msg);
  //   }
  // }


  // async putAllDataToCrp(data) {
  //   data.Token_ID = this.Token_ID
  //   data.CIS_Score = this.scores.cis.toString()
  //   data.GRS_Score = this.scores.grs.toString()
  //   data.CSS_Score = this.scores.css.toString()
  //   let res;
  //   try {
  //     res = await this.airtable.postCrpData(data)
  //     console.log("response from post req of the Crp : ", res);
  //     res = res.success;
  //   } catch (error) {
  //     console.log("error from post req of crp:", error);
  //   }
  //   return res;
  // }


  // updateCRP() {
  //   let crp_data_local = Object.assign({}, this.crp_data);
  //   delete crp_data_local.ID;
  //   this.airtable.postCrpData(crp_data_local).then(res => {
  //     console.log('The response after doing the put request is');
  //     console.log(res);
  //   }).catch(err => {
  //     console.log(err, "logg");
  //   })
  // }


  ///////// CRP DATA END /////////



  /////////////////////////////
  /// this code for CIS Section
  /////////////////////////////
  async getBussinessClass() {
    try {
      let res: any = await this.airtable.getBussinessClasses();
      console.log("Response of Bussiness Class", res);
      if (res.status) {
        this.bussiness_classes = res.success;
      }
    } catch (error) {
      console.log("Got Error from Get Bussiness Class :", error);
    }
  }

  async selectBussinesClass(e, category) {
    this.selected_bussiness = category;
    this.business_sub_classes = null
    try {
      let res: any = await this.airtable.getBussinessSubClasses(category)
      if (res.status) {
        this.business_sub_classes = res.success
      }
    } catch (error) {
      console.log(error, "errorl");
    }
  }

  async getRegularCategories(e, category) {

    this.selected_sub_bussiness = category;
    this.regulator_category = null
    try {
      this.getBussinessMetricSubclass();
      this.getImpactScoreForImact_reg();
      let res: any = await this.airtable.getRegularCategory(this.selected_bussiness, this.selected_sub_bussiness)
      console.log('response of get regular category :', res);
      if (res.status) {
        this.regulator_category = res.success
      }
    } catch (error) {
      console.log("Error From get req of regular category :", error);
      let msg = {
        type: "error",
        message: "Internal Server Error!"
      }
      this.openNotification(msg);
    }

  }


  imact_score_reg: any

  eu_regulations_arr: any = []
  usa_regulations_arr: any = []

  main_usa_regulations_arr: any = []
  main_eu_regulations_arr: any = []
  new_semi_imapact_cis_score_arr: any = []
  async getImpactScoreForImact_reg() {
    try {
      let res: any = await this.airtable.getImpactScoreForImact_reg(this.selected_bussiness, this.selected_sub_bussiness)
      // console.log('response of get impact _rega regular category :', res);
      this.semi_impact_cis_score_arr = res.success;
      let value = 0;
      let length_of_imact_reg = 0

      for (let i of this.semi_impact_cis_score_arr) {
        value = Number(value) + Number(i['Impact Score'])
        if (i['Impact_Regulation'] == '1') {
          length_of_imact_reg = length_of_imact_reg + 1
        }


        if (i['Location'] && i['Location'] == 'USA') {
          this.main_usa_regulations_arr.push(i);
        }

        if (i['Location'] && i['Location'] == 'EU') {
          this.main_eu_regulations_arr.push(i);
        }
      }
      if (this.main_usa_regulations_arr.length > 0) {
        this.total_count_usa = this.main_usa_regulations_arr.length
        this.usa_regulations_arr = this.main_usa_regulations_arr.slice(0, 5);
      }

      if (this.main_eu_regulations_arr.length > 0) {
        this.total_count_eu = this.main_eu_regulations_arr.length
        this.eu_regulations_arr = this.main_eu_regulations_arr.slice(0, 5);
      }

      if (this.semi_impact_cis_score_arr.length > 0) {
        this.total_count_all = this.semi_impact_cis_score_arr.length
        this.new_semi_imapact_cis_score_arr = this.semi_impact_cis_score_arr.slice(0, 5)
      }



      let avg_val = value / this.semi_impact_cis_score_arr.length
      this.semi_impact_cis_score = avg_val.toFixed(3);
      this.getUicValue(length_of_imact_reg)

    } catch (error) {
      let msg = {
        type: "error",
        message: "Internal Server Error!"
      }
      this.openNotification(msg);
    }
  }

  uic_metric: any
  async getUicValue(number) {
    try {
      let res: any = await this.airtable.getUicValue(number)
      // console.log("Response from get uic", res);
      if (res.status) {
        for (const obj of res.success) {
          if (obj['Major Item Total'] == number) {
            this.uic_metric = obj['Metric'];
          }
        }
      }
    } catch (error) {
      console.log(error, "err");
    }
  }



  reg_adjustment_metric_score: any
  async getImactScores(category) {
    this.selected_reg_category = category;
    try {

      let regMetricsRes: any = await this.airtable.getRegMetrics(category);
      // console.log("Reg Metrics From Reg Metric Api:", regMetricsRes);

      if (regMetricsRes.success[0]) {
        this.reg_adjustment_metric_score = regMetricsRes.success[0]['Adjustment Metrics'];
      } else {
        let msg = {
          type: "error",
          message: "'Adjustment Metric is Missing!"
        }
        this.openNotification(msg);
        this.reg_adjustment_metric_score = 0
      }

    } catch (error) {
      console.log("error from get req of Impact Score", error);
    }
  }


  bus_adjustment_metric_score: any

  async getBussinessMetricSubclass() {
    try {
      let res: any = await this.airtable.getBussinessMetricSubclass(this.selected_sub_bussiness);
      // console.log("Adjustment Metric Score:", res.success);
      if (res.success[0]) {
        this.bus_adjustment_metric_score = res.success[0]['Adjustment Metric'];
      }
    } catch (error) {
      // console.log("error from get req of adjustment metric score", error);
    }
  }


  location_risk_metric: any
  async getLocationRiskMetric() {
    try {
      let res: any = await this.airtable.getLocationRiskMetric(this.crp_data.Legal_Domicile)
      if (res.success[0]) {
        this.location_risk_metric = res.success[0]['Risk Metric'];
      }
    } catch (error) {
    }
  }



  eu_page_num: any = 1
  total_count_eu: any
  all_page_num: any = 1
  total_count_all: any
  pageChanged(event, type) {
    if (type == 'usa') {
      this.usa_page_num = event.page
      this.usa_regulations_arr = this.main_usa_regulations_arr.slice((this.usa_page_num - 1) * 5, this.usa_page_num * 5);
    } else if (type == 'eu') {
      this.eu_page_num = event.page
      this.eu_regulations_arr = this.main_eu_regulations_arr.slice((this.eu_page_num - 1) * 5, this.eu_page_num * 5);
    } else {
      this.all_page_num = event.page
      this.new_semi_imapact_cis_score_arr = this.semi_impact_cis_score_arr.slice((this.all_page_num - 1) * 5, this.all_page_num * 5);
    }
  }

  closeModal(close) {
    this.show = close
  }



  // calcCIS() {

  //   for (let k in this.get_cis_score) {
  //     if (this.get_cis_score[k] == '') {
  //       this.cis_form_err = true
  //       return
  //     }
  //   }
  //   let annual_revenue_risk_score = this.get_cis_score["Annual Revenue ($ million)"];

  //   for (let obj of this.revenues) {
  //     if (obj["Revenue Number"] == annual_revenue_risk_score) {
  //       annual_revenue_risk_score = Number(obj['Risk Metric'])
  //     }
  //   }
  //   console.log(annual_revenue_risk_score, 'anual revenue risk score');



  //   this.cis_form_err = false

  //   if (this.footprint == 'EU') {
  //     this.scores.cis = (Number(this.semi_impact_cis_score)) - Number(this.location_risk_metric) - Number(this.get_cis_score['Employee Count']) - annual_revenue_risk_score
  //   } else if (this.footprint == 'Both') {

  //     let x = (Number(this.semi_impact_cis_score) * Number(this.uic_metric)) - Number(this.reg_adjustment_metric_score) - Number(this.bus_adjustment_metric_score) - Number(this.location_risk_metric) - Number(this.get_cis_score['Employee Count']) - annual_revenue_risk_score

  //     let y = 0.6647 - Number(this.location_risk_metric) - Number(this.get_cis_score['Employee Count']) - annual_revenue_risk_score

  //     this.scores.cis = (x + y) / 2


  //   } else {
  //     this.scores.cis = (Number(this.semi_impact_cis_score) * Number(this.uic_metric)) - Number(this.reg_adjustment_metric_score) - Number(this.bus_adjustment_metric_score) - Number(this.location_risk_metric) - Number(this.get_cis_score['Employee Count']) - annual_revenue_risk_score
  //   }

  //   this.scores.cis = Number(this.scores.cis).toFixed(3);

  //   this.putScores();
  //   this.updateScoresGraphData();
  //   this.putAllDataToCrp(this.get_cis_score);
  // }





  //////// CIS DATA END ////////////



  /////////////////
  // GRS DATA
  ////////////////

  sub_grs: any
  modren_grs: any = {}
  grsErr: any

  saveGrsValues(e, i, v, k) {
    if (e) {
      e.preventDefault();
    }
    this.sub_grs[i].selected = v.key;
    this.sub_grs[i].ans_string = v.value
  }
  total_selcted_mutliple_check: number = 0
  total_check_times: number = 0

  getSelectedMultipleOpt(item, i, multi_obj) {
    if (!item.checked) {
      this.total_check_times = this.total_check_times + 1
      this.total_selcted_mutliple_check = this.total_selcted_mutliple_check + Number(item.value)

    } else {
      this.total_check_times = this.total_check_times - 1
      this.total_selcted_mutliple_check = this.total_selcted_mutliple_check - Number(item.value)
    }


    let index = 0;
    let find;
    for (const obj_item of this.sub_grs[i].seleted_multiple_array) {
      if (obj_item && obj_item == item.string) {
        this.sub_grs[i].seleted_multiple_array.splice(index, 1)
        find = true
        break
      }
      index++
    }

    if (!find) {
      this.sub_grs[i].seleted_multiple_array.push(item.string);
    }
    this.sub_grs[i].selected = "";
    let new_index = 0;
    this.sub_grs[i].seleted_multiple_array.forEach(element => {
      if (this.sub_grs[i].seleted_multiple_array.length - 1 > new_index) {
        this.sub_grs[i].selected = this.sub_grs[i].selected + element + " , "
      } else {
        this.sub_grs[i].selected = this.sub_grs[i].selected + element
      }
      new_index++
    });
  }
  grs_step_completed_now: boolean = true
  async nextStep() {

    let expected_revenue: any = {
      "Less than 1.0": "0.790",
      "1.0 - 4.99": "0.790",
      "5.0 - 19.99": "0.720",
      "20.0 - 49.99": "0.660",
      "50.0 - 99.99": "0.600",
      "100.0 - 499.99": "0.600",
      "500.0 - 999.99": "0.545",
      "1,000.0 - 4,999.99": "0.545",
      "5,000.0 or more": "0.500",
    }



    //////
    //validations run here
    //////


    if (this.grs_step == 1) {
      if (!this.get_cis_score['Annual Revenue ($ million)'] || this.get_cis_score['Annual Revenue ($ million)'] == '') {
        this.grsErr = true
        this.loading = false
        return
      }
    }


    let annual_revenue_selected = this.get_cis_score["Annual Revenue ($ million)"];
    let annual_revenue_value;
    for (let obj of this.revenues) {
      if (obj["Revenue Number"] == annual_revenue_selected) {
        annual_revenue_value = Number(obj['value'])
      }
    }
    if (!annual_revenue_value) {
      this.grsErr = true
      return
    }
    let data_obj = {
      "Annual Revenue ($ million)": annual_revenue_selected
    }
    console.log("Posting only anual revenue");
    // let posting_data_to_crp = this.putAllDataToCrp(data_obj);

    if (this.grs_step == 1) {
      this.sub_grs = this.GRS.slice(0, 7);
      this.grs_step = this.grs_step + 1;
      return
    }


    for (let obj of this.sub_grs) {
      if (!obj.selected) {
        this.grsErr = true
        return
      }
    }


    this.grsErr = false


    ////////////////////////////////////////
    /// posting data to Crp api in every step
    ///////////////////////////////////////

    //////
    // Modifying answers of multiple checkboxes type Questions
    //////
    if (this.sub_grs) {

      let ans_score, length_of_answered;
      for (let sub_grs_obj of this.sub_grs) {
        if (sub_grs_obj.is_multiple && sub_grs_obj.new_options && sub_grs_obj.selected_values_arr) {
          sub_grs_obj.selected_values_arr = [];
          ans_score = 0;
          length_of_answered = 0
          for (let checked_obj of sub_grs_obj.new_options) {
            if (checked_obj.checked) {
              length_of_answered++
              sub_grs_obj.selected_values_arr.push(Number(checked_obj.value))
              ans_score = ans_score + checked_obj.value;
            }
          }


          if (sub_grs_obj.formula == 'complicated') {
            let formula_apply = ans_score / (0.01 + length_of_answered);
            sub_grs_obj.ans_string = Number(formula_apply).toFixed(3)
          }

          if (sub_grs_obj.formula && sub_grs_obj.formula == 'easy') {
            sub_grs_obj.ans_string = ans_score * (-1);
            sub_grs_obj.ans_string = Number(sub_grs_obj.ans_string / 1.5).toFixed(3);
          }

          if (sub_grs_obj.formula && sub_grs_obj.formula == 'no') {
            sub_grs_obj.ans_string = Number(ans_score / length_of_answered).toFixed(3)
          }

          if (sub_grs_obj.formula && sub_grs_obj.formula == 'considermultiple') {
            sub_grs_obj.ans_string = Number(ans_score).toFixed(3)
          }

        }
      }
    }


    // let css_answered_arr = await this.db.answers.toArray()
    let converted_obj = this.sub_grs.reduce((acc, obj) => {
      if (!obj.selected) {
        obj.selected = ""
      }
      acc[obj.question] = obj.selected
      return acc
    }, {})

    // converted_obj["Annual Revenue ($ million)"] = this.get_cis_score["Annual Revenue ($ million)"];


    // let posting_data = this.putAllDataToCrp(converted_obj);
    // if (posting_data) {
    //   console.log("Posting Data to CRP in GRS Section : ", posting_data);
    // }

    let value = 0
    let grs9_selected_answers = 1;
    this.GRS.forEach(el => {
      if (el.ans_string) {
        value += Number(el.ans_string)
      }

      if (el.formula && el.formula == 'considermultiple') {
        console.log("consider multiple formula is using actually");
        if (el.selected_values_arr && el.selected_values_arr.length) {
          grs9_selected_answers = el.selected_values_arr.length
          console.log('choosed Answers count is');
          console.log(grs9_selected_answers);
        }
      }
    });


    /////
    // now remove the employee factor from caclulation part because we are multiplying at last
    //////
    console.log("the Employee Factor is :");
    console.log(this.GRS[2].ans_string);

    console.log("Calculated 20 questions here , value is :");
    value = Number(value) - this.GRS[2].ans_string
    console.log(value);


    let type_value;
    if (this.crp_data.Organisation_Type == 'Public') {
      console.log("Now it is", "Public", 0.72);
      type_value = 0.72
    } else {
      console.log("Now it is", "Private", 0.6);
      type_value = 0.6
    }

    console.log("Annual Revenue is");
    console.log(annual_revenue_value);


    console.log("expected Revenue is");
    console.log(Number(expected_revenue[annual_revenue_selected]));


    value = value + Number(annual_revenue_value) + Number(type_value) + Number(expected_revenue[annual_revenue_selected])
    console.log('testing the answers');
    console.log(this.GRS);
    console.log("this is most final value ", value);


    console.log("Total Choose options Count", this.GRS.length + 1 + grs9_selected_answers);

    value = (value / (this.GRS.length + 1 + grs9_selected_answers)) * this.GRS[2].ans_string;
    value = Number(value) * 1.2;
    if (this.grs_step == 4) {
      this.scores.grs = value.toFixed(3);
      // this.putScores();
      this.grs_step_completed_now = true
      return
    }

    this.sub_grs = this.GRS.slice((this.grs_step - 1) * 7, this.grs_step * 7);

    if (this.grs_step <= 3) {
      this.grs_step = this.grs_step + 1;
    }


    // this.updateStepCountInFB_DB();

  }

  goToThatStep(step) {

    if (step != 1) {
      if (this.grs_step < step) {
        if (step != 2) {
          for (let obj of this.sub_grs) {
            if (!obj.selected) {
              this.grsErr = true
              return
            }
          }
        }
      }
      this.grsErr = false
      this.grs_step = step;
      this.sub_grs = this.GRS.slice((this.grs_step - 2) * 7, (this.grs_step - 1) * 7);
    } else {
      this.grs_step = step;
    }

  }

  getDataForGrs() {
    let index = 0
    for (let obj of this.GRS) {
      if (this.crp_data.hasOwnProperty(obj.question)) {
        let selected_ans = this.crp_data[obj.question];
        this.GRS[index]["selected"] = selected_ans
        let value_for_check;
        if (selected_ans == 'Not Sure') {
          value_for_check = "1"
        } else if (selected_ans == 'No') {
          value_for_check = "0"
        } else {
          value_for_check = "2"
        }
        this.modren_grs[obj.question] = value_for_check
        for (let key in this.GRS[index]['options']) {
          if (key == selected_ans) {
            this.GRS[index]["ans_string"] = this.GRS[index]['options'][key];
          }
        }




        if (obj.is_multiple) {
          let split_ans = selected_ans.split(' , ')
          obj.seleted_multiple_array = split_ans;
          let sel_index = 0
          for (let smallkeyobj of obj.new_options) {

            if (obj && obj.new_options && obj.selected_values_arr) {
              obj.selected_values_arr = []
              for (const checked_obj of obj.new_options) {
                if (checked_obj.checked) {
                  obj.selected_values_arr.push(Number(checked_obj.value))
                }
              }

            }

            for (let smallans of split_ans) {
              if (smallans == smallkeyobj.string) {
                obj.new_options[sel_index].checked = true
              }
            }
            sel_index++
          }

          obj.ans_string = 0
          for (const direct_value of obj.selected_values_arr) {
            obj.ans_string = obj.ans_string + direct_value
          }

          if (obj.formula && obj.formula == 'complicated') {
            obj.ans_string = obj.ans_string / (0.01 + obj.selected_values_arr.length);
          }

          if (obj.formula && obj.formula == 'easy') {
            obj.ans_string = obj.ans_string * (-1);
            obj.ans_string = obj.ans_string / 1.5;
          }

          // if (obj.formula && obj.formula == 'considermultiple') {
          //   obj.ans_string = Number(obj.ans_string).toFixed(3)
          // }

          // if (obj.formula && obj.formula == 'no') {
          //   console.log("is it 2?", obj.selected_values_arr.length );            
          //   obj.ans_string = obj.ans_string / obj.selected_values_arr.length
          // }

        }
      }
      index = index + 1
    }

  }


  valueAscOrder = (a, b) => {
    return 0;
  }

  nowShowGrsScore(e) {
    this.getUserInfo();
    // this.scores.composite_grs_score = Number(e.composite_grs_score).toFixed(2)
    // this.putScores()
    this.show_grs_score_in_ui = true
  }

  ////////// GRS DATA END ////////



  ///////////////////////
  /// this functions for CSS DATA
  ////////////////


  async getDataForStepCss(current_step) {
    //////////////
    // without generating GRS score and CIS score we can't run CSS Function
    /////////////
    // await this.getScores();
    if (this.scores.cis == 0 || this.scores.grs == 0) {
      return;
    }
    console.log('The current CSS step is:  ' + current_step);
    let css_all_questions = CONFIG.QUESTIONS.CSS;

    for (let i in css_all_questions) {
      for (let key in this.crp_data) {
        if (css_all_questions[i]['question'] == key) {
          let valueofQus = css_all_questions[i]['options'][this.crp_data[key]]
          css_all_questions[i]['selected_score'] = valueofQus;
          css_all_questions[i]['selected'] = this.crp_data[key];

          // this if condition for first two qus of CSS 
          // these two are diffeent options to calculate score

          if (i == '0' || i == '1') {
            // this is string with no of days 
            // now will split it
            let ans_string = this.crp_data[key];
            let ans_splited_arr = ans_string.split('_')
            let number_of_days;
            if (ans_splited_arr[1]) {
              css_all_questions[i]['first_two_ans'] = ans_splited_arr[1]
              let find_number = ans_splited_arr[1].split(" ")
              number_of_days = find_number[0].toString().trim()
              if (number_of_days) {
                // now will calculate with formaula
                let calculated_score = Number(number_of_days) * (-0.0017)
                css_all_questions[i]['selected_score'] = calculated_score
              }
            }
          }

        }
      }
    }


    console.log(CONFIG.QUESTIONS.CSS, 'now data');

    if (current_step > this.css_step) {
      if (current_step > 1) {
        for (let obj of this.sub_css) {
          if (!obj.selected) {
            this.cssErr = true
            return
          }
        }

        if (current_step == 3 || current_step == 4) {
          let useful_qus = CONFIG.QUESTIONS.CSS.slice((current_step - 1 - 1) * 4, (current_step - 1) * 4);
          for (let obj of useful_qus) {
            if (!obj['selected']) {
              this.cssErr = true
              return
            }
          }
        }
      }
    }
    this.cssErr = false
    this.css_step = current_step;
    let current_four_qus = CONFIG.QUESTIONS.CSS.slice((current_step - 1) * 4, current_step * 4);

    this.sub_css = current_four_qus;
    return current_four_qus;
  }

  openInput: boolean = false
  notEnteredDays: boolean = true
  no_of_days_question: any
  specific_no_days: any
  choosen_option_first: any

  final_saved_string: any
  async saveCssValues(e, i, k, q, v, sub_ind) {
    e.preventDefault();
    this.sub_css[i].selected = k;
    this.final_saved_string = null
    if (this.css_step == 1) {
      if ((i == 0 || i == 1) && sub_ind === 4) {
        console.log('open Input', this.openInput);

        this.openInput = true
        this.no_of_days_question = q;
        this.choosen_option_first = i
        this.notEnteredDays = true
      } else {
        this.no_of_days_question = null
        this.openInput = false
        this.sub_css[i]['first_two_ans'] = null
      }
    }
    this.sub_css[i].selected_score = v
    // let data = this.db.answers.where('question').equals(q).modify({ 'answer': v, 'ans_string': k });
  }

  setValueForMorethanDays(e) {

    if (e.target.value && e.target.value != "") {
      let value_for_qus = Number(e.target.value) * (-0.0017)
      // let data = this.db.answers.where('question').equals(this.no_of_days_question).modify({ 'answer': value_for_qus, 'ans_string': this.sub_css[this.choosen_option_first].selected + '-' + e.target.value + " Days " }); 
      this.notEnteredDays = false
      this.sub_css[this.choosen_option_first].selected_score = value_for_qus
      this.final_saved_string = this.sub_css[this.choosen_option_first].selected + "_" + e.target.value + " Days"
      this.sub_css[this.choosen_option_first].first_two_ans = e.target.value + " Days";
    } else {
      this.notEnteredDays = true
    }
  }

  saveDays(e) {
    if (e) {
      e.preventDefault();
    }
    this.no_of_days_question = null
    this.openInput = false
    this.specific_no_days = null
    this.sub_css[this.choosen_option_first].selected = this.final_saved_string

    this.form = this.fb.group({
      specific_no_days: [null, Validators.compose([
        Validators.required,
        Validators.max(180),
        Validators.min(31)
      ])],
    })
  }

  closeInpModal() {
    this.no_of_days_question = null
    this.openInput = false
    this.specific_no_days = null
    this.sub_css[this.choosen_option_first].selected = null
    this.sub_css[this.choosen_option_first].first_two_ans = null
    this.form = this.fb.group({
      specific_no_days: [null, Validators.compose([
        Validators.required,
        Validators.max(180),
        Validators.min(31)
      ])],
    })
  }


  async cssNextStep() {

    for (let obj of this.sub_css) {
      if (!obj.selected) {
        this.cssErr = true
        return
      }
    }

    this.cssErr = false


    ////////////////////////////////////////
    /// posting data to Crp api in every step
    ///////////////////////////////////////
    // let css_answered_arr = await this.db.answers.toArray()
    // console.log(css_answered_arr , 'c');

    let converted_obj = this.sub_css.reduce((acc, obj) => {
      if (!obj.selected) {
        obj.selected = ""
      }
      acc[obj.question] = obj.selected
      return acc
    }, {})

    console.log(converted_obj, 'converted obj');


    // let posting_data = await this.putAllDataToCrp(converted_obj);


    // // let getdata = await this.getCrpData
    // if (posting_data) {
    //   this.crp_data = posting_data
    //   console.log("Posting Data to CRP in CSS Part : ", posting_data);
    // }

    ////////////////////////////////////////
    /// End of posting data 
    ///////////////////////////////////////
    if (this.css_step <= 3) {
      this.css_step = Number(this.css_step) + 1;
      this.getDataForStepCss(this.css_step)
    } else {
      // let total_count = await this.db.answers.toArray()
      // let value = 0
      // total_count.forEach(el => {
      //   value += Number(el.answer)
      // })
      let value = 0
      this.CSS.forEach((el: any) => {
        value += Number(el.selected_score)
      })

      this.semi_css = Number(value).toFixed(3);

      ////////////////////      
      // now we can run calculation part
      ////////////////////
      let final_css_score = (Number(this.scores.cis) + (2 * Number(this.scores.grs)) + Number(this.semi_css)) / 3;
      console.log('final CSS Score', final_css_score);
      this.scores.css = Number(final_css_score).toFixed(3);
      this.remove_sugg = true
      window.localStorage.setItem("css score", this.scores.css.toString())
      // this.putScores();
    }

    if (this.CSS.length > 0) {
      this.sub_css = this.CSS.slice((this.css_step - 1) * 4, this.css_step * 4);
    }
    // this.updateStepCountInFB_DB();
  }



  //////////// CSS DATA END  //////////




  show_more_text: any
  setShowMore(risk_texts) {
    if (risk_texts == "composite_GRS") {
      this.show_more_text = this.naratives['composite_GRS']['info'];
      return
    }
    this.show_more_text = this.naratives[this.footprint][risk_texts];
  }


  setActiveTab(type) {
    if (type == this.footprint) {
      return true
    }
  }


  /////////////////////
  //// Helper Functions
  ///////////////////////

  openNotification(msg) {
    this.error_message.type = msg.type
    this.error_message.message = msg.message
    this.show = true
  }



  async getUserInfo() {
    try {
      let res = await this.authService.profile();
      let response = res.json()
      console.log(response);
      await this.getCountriesData();
      if (response.success) {
        this.loginInfo.first_name = response.user.first_name
        this.loginInfo.last_name = response.user.last_name
        if (response.user) {
          
          this.crp_data = response.user
          this.footprint = this.crp_data.global_footprint;
          this.crp_data.cis_score = parseFloat(this.crp_data.cis_score);
          this.crp_data.css_score_to_show = parseFloat(this.crp_data.css_score_to_show);
          this.crp_data.grs_score = parseFloat(this.crp_data.grs_score);

          this.crp_data.selectedCountries = "";
          if (this.countriesData.countries && this.crp_data.countryIds) {
            let countries_data = this.countriesData.countries.filter(country => this.crp_data.countryIds.includes(country.id));
            if (countries_data && countries_data.length) {
              let country_names = countries_data.map(ele => ele.country_name);
              this.crp_data.selectedCountries = country_names.join(", ");
            }
          }

          if (this.crp_data.cis_score) {
            this.scores = {
              css: this.crp_data.css_score_to_show == '0' ? this.crp_data.css_score_to_show : Number(this.crp_data.css_score_to_show).toFixed(3),
              cis: this.crp_data.cis_score == '0' ? this.crp_data.cis_score : Number(this.crp_data.cis_score).toFixed(3),
              grs: this.crp_data.grs_score == '0' ? this.crp_data.grs_score : Number(this.crp_data.grs_score).toFixed(3),
            }

            // if composite_grs exits priory should be for this
            if (this.crp_data.composite_grs_score) {

              let data_governance = this.crp_data.data_governance_score
              let physical_security = this.crp_data.physical_security_score
              let internal_security = this.crp_data.internal_security_score
              let external_security = this.crp_data.external_security_score

              if (data_governance && physical_security && internal_security && external_security) {
                // now we can show the score in Ui
                console.log("Now Composite score is ready");
                this.show_grs_score_in_ui = true

                if (Number(this.crp_data.composite_grs_score) > 0) {
                  this.scores.composite_grs_score = Number(this.crp_data.composite_grs_score).toFixed(3)
                }
              } else {
                // Dont show the GRS Score
                this.show_grs_score_in_ui = false
              }
            }
          }
          // this.getcisvalues(this.cisInfo,'business_class','class')
        }
        await this.updateScoresGraphData()
      }
    } catch (error) {
      console.log(error);
    }
  }

  calcis(e) {
    this.getUserInfo()
  }


}
